import DataSource from "src/core/data-sources/dataSource";
import EventBus from "src/core/common/eventBus";
import Cache from "src/core/common/cache";
import autoBind from "auto-bind";
import * as ShopsApi from "../../api/shops";
import makeSocialLink from "src/core/common/models/socials";

class ShopSocialsDataSource extends DataSource {
  constructor() {
    super();
    autoBind(this);
  }

  doGet(keyValueMap = {}) {
    const cachedItem = this.getCachedAsync(keyValueMap);

    if (cachedItem && !cachedItem.preCached) return cachedItem;

    const promise = ShopsApi.socials().then((response) => {
      const key = this.getCacheKey();
      const links = makeSocialLink(response.data);
      cache.put(key, links);
      return links;
    });

    cache.put(this.getCacheKey(), promise);

    if (cachedItem) {
      setTimeout(() =>
        promise.then((data) => {
          this.notifyNewData(keyValueMap, data);
        })
      );
      return cachedItem;
    }

    return promise;
  }

  getCached(keyValueMap) {
    const params = this.getParams(keyValueMap);
    const key = this.getCacheKey(params);
    return cache.get(key);
  }

  getCachedAsync = (keyValueMap) => {
    const params = this.getParams(keyValueMap);
    const key = this.getCacheKey(params);
    return cache.getAsync(key);
  };

  getCacheKey = () => {
    return "ShopSocials";
  };

  getParams = (params) => {
    return params;
  };

  getEventBus() {
    return eventBus;
  }

  save(obj) {
    const key = this.getCacheKey();
    const result = makeSocialLink(obj);
    cache.put(key, result, true);
  }

  static clear() {
    cache.clear();
  }
}

const cache = new Cache(300000, ["ShopSocials"]);

const eventBus = new EventBus();

export default ShopSocialsDataSource;
