import get from "lodash/get";

const tymberSocials = {
  facebook: {
    background: "#1877f2",
    icon: "./facebook-long.svg",
    width: 68,
    height: 24,
  },
  instagram: {
    background: "#d7005a",
    icon: "./instagram-long.svg",
    width: 72,
    height: 28,
  },
  weedmaps: {
    background: "#221f1f",
    icon: "",
    width: 72,
    height: 24,
  },
  yelp: {
    background: "#d32323",
    icon: "./yelp-long.svg",
    width: 68,
    height: 24,
  },
  google: {
    background: "#ffffff",
    icon: "./google-long.svg",
    width: 68,
    height: 24,
  },
  tiktok: {
    background: "#000000",
    icon: "./tiktok-long.svg",
    width: 78,
    height: 34,
  },
  linkedin: {
    background: "#15487d",
    icon: "./linkedin-long.svg",
    width: 68,
    height: 24,
  },
  twitter: {
    background: "#32b1fe",
    icon: "./twitter-long.svg",
    width: 68,
    height: 24,
  },
};

class Socials {
  constructor(socialsObject) {
    this.obj = socialsObject;
  }

  get socialLinks() {
    return this.obj.map((link) => new SocialLink(link));
  }
}

class SocialLink {
  constructor(socialLinkObject) {
    this.obj = {
      ...socialLinkObject.attributes,
      ...tymberSocials[socialLinkObject.attributes.name],
    };
  }

  get name() {
    return get(this.obj, "name");
  }

  get isActive() {
    return get(this.obj, "is_active");
  }

  get linkTo() {
    return get(this.obj, "link");
  }

  get background() {
    return get(this.obj, "background");
  }

  get backgroundColor() {
    return this.background;
  }

  get icon() {
    return get(this.obj, "icon");
  }

  get width() {
    return get(this.obj, "width");
  }

  get height() {
    return get(this.obj, "height");
  }

  get type() {
    return get(this.obj, "name");
  }

  get test() {
    return this.obj;
  }
}

function makeSocials(attrs) {
  return new Socials(attrs);
}

export default makeSocials;
