import {useMemo} from "react";
import {useDataSource} from "src/core/data-sources/decorators/withData";
import makeSocials from "src/core/common/models/socials";
import ShopSocialsDataSource from "../data-sources/shopSocials";

function useSocials() {
  const {data: shopSocials} = useDataSource(
    {},
    {
      dataSource: shopSocialsDataSource,
    }
  );

  return useMemo(() => {
    if (!shopSocials) return [makeSocials([]).socialLinks];
    return [shopSocials.socialLinks];
  }, [shopSocials]);
}

const shopSocialsDataSource = new ShopSocialsDataSource();

export default useSocials;
