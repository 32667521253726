import useSchemaContext from "src/core/seo/components/useSchemaContext";
import React, {useMemo} from "react";
import Head from "next/head";
import useSite from "src/core/sites/hooks/useSite";
import makeProductsListSchema from "src/core/seo/schema-objects/factories/productsList";
import {getProductCatalogUrl, getProductCategoryUrl} from "src/core/seo/meta";

function ProductListSchema({category = null, path = ""}) {
  const ctx = useSchemaContext();
  const site = useSite();

  const canonicalUrl = useMemo(() => {
    if (category) {
      return getProductCategoryUrl(category.getSlug(), ctx);
    } else {
      return getProductCatalogUrl(ctx);
    }
  }, [category, ctx]);

  const schemas = makeProductsListSchema(site, category, ctx);

  return (
    <Head>
      <link rel="canonical" href={canonicalUrl + path} />
      {schemas.map((schema, index) => (
        <script
          key={`head-link-${index}`}
          type={schema.type}
          dangerouslySetInnerHTML={{__html: schema.innerHTML}}
        />
      ))}
    </Head>
  );
}

export default ProductListSchema;
