import makeBreadCrumbList from "../objects/breadcrumbList";
import {getSiteURL, getProductCatalogUrl, getProductCategoryUrl} from "../../meta";

function makeBreadcrumbsProductsListSchema(category = null, ctx) {
  const items = [
    {
      id: getSiteURL(ctx),
      name: "Home",
    },
    {
      id: category
        ? getProductCategoryUrl(category.getSlug(), ctx)
        : getProductCatalogUrl(ctx),
      name: category ? category.getName() : "Products",
    },
  ];

  return makeBreadCrumbList(items, ctx);
}

export default makeBreadcrumbsProductsListSchema;
