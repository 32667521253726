import useRouter from "src/core/common/hooks/useRouter";
import ModalContext from "src/core/common/components/modules/modal/modalContext";
import React, {useContext, useEffect, useState} from "react";
import dynamic from "next/dynamic";
import TymberFooter from "src/core/common/components/elements/TymberFooter";
import {normalizeUrl} from "src/core/common/utils";
import RouteParamsContext from "src/core/common/routeParamsContext";
import {logModuleLoadError} from "src/core/common/utils";

const CoreModalPages = dynamic(
  () =>
    import("src/core/pages/CoreModalPages").catch(
      logModuleLoadError("CoreModalPages", {critical: true})
    ),
  {
    ssr: false,
  }
);

const Cart = dynamic(
  () =>
    import("src/core/checkout/components/cart/Cart").catch(
      logModuleLoadError("Cart", {critical: true})
    ),
  {
    ssr: false,
  }
);

export function makeBasePage(routeFactory) {
  return (props) => {
    const route = routeFactory();

    const [cartOpen, setCartOpen] = useState(false);
    const router = useRouter();
    const params = router.query;
    const globalParams = useContext(RouteParamsContext);
    const {hidden} = useContext(ModalContext);

    const {pathname} = router.resolve({
      pathname: route.pathname,
      params,
    });
    const [previousRoute, setPreviousRoute] = useState({
      pathname: route.pathname,
      params:
        normalizeUrl(router.asPath) === normalizeUrl(pathname)
          ? sanitizeParams({...globalParams, ...params})
          : {...globalParams, ...filterParams(route.params, params)},
    });

    useEffect(() => {
      const {pathname} = router.resolve({
        pathname: route.pathname,
        params,
      });
      if (normalizeUrl(router.asPath) === normalizeUrl(pathname)) {
        setPreviousRoute({
          pathname: route.pathname,
          params: sanitizeParams({...globalParams, ...params}),
        });
      }
      // eslint-disable-next-line
    }, [params, route.pathname, router.asPath, setPreviousRoute]);

    const onCloseModal = () => {
      if (router.query.from) {
        router.push({
          pathname: router.query.from,
        });
      } else {
        router.push({
          ...previousRoute,
          params: {...previousRoute.params},
          query: {s: 1}, // s: 1 to keep previous scroll
        });
      }
    };
    const modalParams = {category: undefined, brand: undefined, ...previousRoute.params};

    return (
      <ModalContext.Provider
        value={{
          modalBasePath: router.pathname,
          modalParams,
          hidden: hidden,
        }}
      >
        <>
          <Cart
            isOpen={cartOpen}
            closeCart={() => setCartOpen(false)}
            openCart={() => setCartOpen(true)}
          />
          {props.children({onRequestClose: onCloseModal, ...props})}
          {!props.hideFooter && <TymberFooter />}
          <CoreModalPages onRequestClose={onCloseModal} />
        </>
      </ModalContext.Provider>
    );
  };
}
const filterParams = (paramNames, params) => {
  if (!paramNames) return {};
  return paramNames.reduce((acc, paramName) => {
    if (params[paramName]) {
      return {...acc, [paramName]: params[paramName]};
    } else {
      return acc;
    }
  }, {});
};

function sanitizeParams(params) {
  return Object.keys(params).reduce((acc, key) => {
    if (params[key]) {
      return {
        ...acc,
        [key]: params[key],
      };
    } else {
      return acc;
    }
  }, {});
}
