import makeOrganizationSchemaObject from "../objects/organization";
import makeWebSiteSchemaObject from "../objects/website";
import makeCollectionPageSchemaObject from "../objects/collectionPage";
import {getProductCategoryUrl, getProductCatalogUrl} from "../../meta";
import makeBreadcrumbsProductsListSchema from "src/core/seo/schema-objects/factories/breadcrumbsProductsList";

function makeProductsListSchema(site, category, ctx) {
  return [
    makeProductsListSchemaHelper(site, category, ctx),
    makeBreadcrumbsProductsListSchema(category, ctx),
  ].map((schema) => ({
    type: "application/ld+json",
    innerHTML: JSON.stringify(schema),
  }));
}

function makeProductsListSchemaHelper(site, category, ctx) {
  return {
    "@context": "https://schema.org/",
    "@graph": [
      makeOrganizationSchemaObject(site, ctx),
      makeWebSiteSchemaObject(site, ctx),
      makeCollectionPageSchemaObject(
        category
          ? {
              url: getProductCategoryUrl(category.getSlug(), ctx),
              name: `${category.getName()} - ${site.getName()}`,
              description:
                (category ? category.getDescription() : null) || site.getDescription(),
            }
          : {
              url: getProductCatalogUrl(ctx),
              name: site.getName(),
              description: site.getDescription,
            }
      ),
    ],
  };
}
export default makeProductsListSchema;
